<template>
  <div class="release-contain" id="new-release">
    <div class="release-wrap">
      <div class="release-info-wrap">
        <div class="release-info" :class="{ 'slide-in': !clicked }" v-show="!clicked">
          <div class="release-head">
            <span class="page-header">
              New Release
            </span>

            <h3>Infamous Bald Head Scaliwags</h3>

            <h4>By Sikai</h4>
          </div>

          <div class="release-body">
            <p class="quote">
              <q><em>I just wanna do bald headed things with my friends</em></q> <span class="highlight">-Sikai</span>.
            </p>

            <p>
              Sikai returns with another collaboration album.
              This time with multi-platinum producer <span class="highlight">Treadway</span>. They team up as the "bald head scaliwags" for a ten track showcase. A Long-awaited album from the "D.R.U.G.S. In America" duo. Stand out tracks like "Terrors" and "Cold Out" display the organic mesh-up the fans have come to know and love.
            </p>
          </div>

          <div class="button-wrap">
            <button class="listen-now" @click="clicked = !clicked">Listen Now</button>
          </div>
        </div>
        <div class="music-buttons" :class="{ 'slide-in': clicked }" v-show="clicked">
          <button class="back-btn" @click="clicked = !clicked">&#x2715;</button>
          <ul>
            <li>
              <a href="https://open.spotify.com/album/709tpxBv8uGLdaYjdHA5wT?si=bX2sBR4VQMa6H1WuxeRg9A" class="icon-btn spotify">
                <img src="../../public/button-icons/spotify.png" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://music.apple.com/us/album/infamous-bald-head-scaliwags/1547867551" class="icon-btn apple">
                <img src="../../public/button-icons/apple.svg" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://www.deezer.com/en/album/198242052" class="icon-btn deezer">
                <img src="../../public/button-icons/deezer.png" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://music.amazon.com/albums/B08SGJJNCL" class="icon-btn amazon">
                <img src="../../public/button-icons/amazon.png" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://music.youtube.com/browse/MPREb_PavXUKYIrIi" class="icon-btn youtube">
                <img src="../../public/button-icons/youtube.png" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://store.tidal.com/us/album/168583174" class="icon-btn tidal">
                <img src="../../public/button-icons/tidal.png" alt="">
              </a>
            </li>
            <span class="divider"></span>
            <li>
              <a href="https://sikai.bandcamp.com/album/infamous-bald-head-scaliwags" class="icon-btn bandcamp">
                <img src="../../public/button-icons/bandcamp.png" alt="">
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="album">
        <img src="../../public/images/album.jpeg" alt="Infamous Bald Head Scaliwags Album Cover">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Release',
  data() {
    return {
      clicked: false
    }
  }
}
</script>

<style lang="scss" scoped>
.release-contain {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;

  .release-wrap {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .release-info-wrap {
      width: 50%;
      text-align: left;

      .release-info {

        .release-head {
          margin-bottom: 25px;

          .page-header {
            letter-spacing: 2px;
            color: var(--orange);
          }

          h3 {
            font-size: 2rem;
          }
        }

        .release-body {
          margin-bottom: 25px;

          .quote {
            margin-bottom: 15px;
          }

          p {
            line-height: 22px;
          }
        }

        .button-wrap {
          padding-top: 25px;

          .listen-now {
            padding: 15px 45px;
            border: 1px solid var(--orange);
            border-radius: 50px;
            font-size: 1rem;
            letter-spacing: 1px;
            background-color: rgba(0,0,0,0);
            color: var(--orange);
            transition: all 0.3s ease-in-out;
            cursor: pointer;

            &:hover {
              background-color: var(--orange);
              color: var(--white);
            }

            &:focus {
              outline: none;
            }
          }
        }
      }

      .music-buttons {
        .back-btn {
          padding: 10px 15px;
          border: none;
          border-radius: 50px;
          font-size: 1rem;
          letter-spacing: 1px;
          background-color: rgba(0,0,0,0);
          color: var(--orange);
          transition: all 0.3s ease-in-out;
          cursor: pointer;

          &:hover {
            background-color: var(--orange);
            color: var(--white);
          }

          &:focus {
            outline: none;
          }
        }
        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          li {
            width: 100%;
            padding: 10px 0px;

            a {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              
              img {
                width: 30%;
              }
            }
          }
          .divider {
            width: 60%;
            height: 2px;
            background-color: rgba(0,0,0,0.1);
            margin: 10px 0px;
          }
        }
      }
    }

    .album {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      img {
        width: 80%;
      }
    }
  }

  .slide-in {
    animation: slide-in 0.5s;
    -webkit-animation: slide-in 0.5s alternate;
  }

  @-webkit-keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  @keyframes slide-in {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .release-contain {
    .release-wrap {
      flex-direction: column-reverse;
      justify-content: flex-end;

      .release-info-wrap {
        width: 100%;

        img {
          width: 100%;
        }

        .release-info {
          .release-head {
            h3 {
              font-size: 1.7rem;
              margin: 10px 0px 5px;
            }
          }
        }

        .music-buttons {
          ul {

            li {
              width: 100%;
              padding: 10px 0px;

              a {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                
                img {
                  width: 60%;
                }
              }
            }
            .divider {
              width: 80%;
            }
          }
        }

        .button-wrap {
          display: flex;
          justify-content: center;
        }
      }

      .album {
        width: 100%;
        justify-content: center;
        margin-bottom: 35px;
      }
    }
  }
}
</style>