<template>
  <div class="footer-contain">
    <div class="footer-wrap">
      <div class="footer-left">
        <p>&copy; 2021 Painkilling Music Group LLC</p>
      </div>
      <div class="footer-right">
        <ul>
          <li>
            <a href="https://www.instagram.com/sikai">
              <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/SikaiMusic">
              <font-awesome-icon class="icon" :icon="['fab', 'twitter']" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCfn4cfepJO6hwm6nVorBV8w">
              <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
            </a>
          </li>
        </ul>
      </div>
        
    </div>
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style lang="scss" scoped>
.footer-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 35px 50px;
  background-color: var(--d-gray);
  color: var(--white);

  .footer-wrap {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-right {
      ul {
        display: flex;

        li {
        margin-left: 15px;

          a {
            font-size: 1.1rem;
            color: var(--white);
            transition: all 0.3s ease;

            &:hover {
              color: var(--l-orange);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .footer-contain {
    padding: 25px;

    .footer-wrap {
      flex-direction: column-reverse;

      .footer-left {
        font-size: 0.9rem;
        padding-top: 15px;
      }
    }
  }
}
</style>