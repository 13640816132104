<template>
  <div class="home-contain">
    <div class="home-wrap">
      <div class="bkg-img">
        <Animation />
        <Nav />
      </div>
    </div>
  </div>
</template>

<script>
import Animation from '../components/AnimationBkg'
import Nav from '../components/Nav'

export default {
  name: 'Home',
  components: {
    Animation,
    Nav
  },
  data() {
    return {
      zHeight: 0,
      fHeight: 100
    }
  },
  methods: {
    adjustedHeight() {
     
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.home-contain {
  width: 100%;
  .home-wrap {
    .bkg-img {
      width: 100%;
      height: 100vh;
      background-image: url('../../public/images/wrestler.jpeg');
      background-size: cover;
      position: relative;
      display: flex;
    }
  }
}

@media (max-width: 992px) {
  .bkg-img {
    background-position: 50%;
  }
}

@media (min-width: 320px) and (max-width: 767px) {}
</style>
