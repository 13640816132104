<template>
  <div class="nav-contain">
    <div class="nav-wrap">
      <div class="nav-options">
        <ul>
          <li>
            <a href="/#new-release">
              New Release
            </a>
            <span class="notif"></span>
          </li>

          <!-- <li class="divider"></li>

          <li>
            <a href="#">
              Music Videos
            </a>
          </li> -->
        </ul>
      </div>
      <div class="logo">
        <h1>sikai</h1>
      </div>
      <div class="social-options">
        <ul>
          <li>
            <a href="https://www.instagram.com/sikai">
              <font-awesome-icon class="icon" :icon="['fab', 'instagram']" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/SikaiMusic">
              <font-awesome-icon class="icon" :icon="['fab', 'twitter']" />
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UCfn4cfepJO6hwm6nVorBV8w">
              <font-awesome-icon class="icon" :icon="['fab', 'youtube']" />
            </a>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<style lang="scss" scoped>
.nav-contain {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 35px 50px;
  z-index: 1;

  .nav-wrap {
    width: 100%;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    align-items: center;


    .nav-options {
      opacity: 0;
      animation: slideLeft 1.5s ease-in-out 1.5s forwards;

      ul {
        display: flex;
        align-items: center;

        .divider {
          width: 1px;
          height: 25px;
          background-color: var(--white);
          margin: 0px 15px
        }

        li {
          font-size: 1rem;
          font-weight: 400;

          a {
            color: var(--white);
            transition: all 0.3s ease;

            &:hover {
              color: var(--white-hov);
              text-decoration: underline;
            }
          }
          .notif {
            width: 5px;
            height: 5px;
            background-color: var(--l-orange);
            border-radius: 50px;
            position: absolute;
          }
        }
      }
    }
    
    .logo {
      color: var(--white);
      opacity: 0;
      animation: slideDown 1.5s ease-in-out 1.5s forwards;

      h1 {
        font-family: 'Coda Caption', sans-serif;
        font-size: 3.5rem;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        text-shadow: 1px 3px 5px rgba(0,0,0,0.6);
      }
    }

    .social-options {
      opacity: 0;
      animation: slideRight 1.5s ease-in-out 1.5s forwards;

      ul {
        display: flex;
        justify-content: flex-end;

        li {
          margin-left: 15px;

          a {
            font-size: 1.3rem;
            color: var(--white);
            transition: all 0.3s ease;

            &:hover {
              color: var(--white-hov);
            }
          }
        }
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .nav-contain {
    width: 100%;
    height: 100vh;
    .nav-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .notif {
        display: none;
      }
    }
  }
}

@keyframes slideLeft {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slideDown {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
@keyframes slideRight {
  from {
    transform: translateX(100px);
    opacity: 0;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>