<template>
  <div class="animation-contain">
    <div class="dark-bkg"></div>

    <span class="rekt r-1"></span>
    <span class="rekt r-2"></span>
    <span class="rekt r-3"></span>
    <span class="rekt r-4"></span>
  </div>
</template>

<script>
export default {
  name: 'Animation'
}
</script>

<style lang="scss" scoped>
.animation-contain {
  width: 100%;
  display: flex;
  
  .dark-bkg {
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    z-index: 1;
  }

  .rekt {
    width: 25%;
    background-color: #e9e9e9;
    z-index: 2;
  }

  .r-1 {
    animation: slideUp 1.5s ease-in-out 0.3s forwards;
  }
  .r-2 {
    animation: slideUp 1.5s ease-in-out 0.6s forwards;
  }
  .r-3 {
    animation: slideUp 1.5s ease-in-out 0.9s forwards;
  }
  .r-4 {
    animation: slideUp 1.5s ease-in-out 1.2s forwards;
  }
}

@keyframes slideUp {
  from {
    height: 100%;
  }
  to {
    height: 0%;
  }
}
</style>