<template>
  <div>
    <Home />
    <NewRelease />
    <Footer />
  </div>
</template>

<script>
import Home from './pages/Home.vue'
import NewRelease from './pages/NewRelease.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    Home,
    NewRelease,
    Footer
  }
}
</script>

<style>
:root {
  --black: #0e0e10;
  --d-gray: #1c1c21;
  --white: #e9e9e9;
  --white-hov: #c5c5c5;
  --orange: #c55a32;
  --l-orange: #e86f42;
}

* {
  box-sizing: border-box;
  margin: 0px;
}

#app {
  font-family: 'Montserrat', sans-serif;
  background-color: var(--white);
  text-align: center;
  color: var(--black);
}

ul {
  list-style: none;
  padding: 0px;
}

a {
  text-decoration: none;
}

.highlight {
  color: var(--orange);
}
</style>
